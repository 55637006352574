<template>
    <div class="border-b pb-3 mb-6 flex justify-between">
        <ModalAddItem
            v-if="modalAddItemVisible"
            :modalAddItem="modalAddItem"
            :invoiceUuid="invoiceUuid"
            @refresh="$emit('refresh')"
            @close="modalAddItem = false"
            @closed="modalAddItemVisible = false"
        />

        <div class="flex items-center">
            <el-tag type="success" size="medium">
                {{ $t(`economy.${invoiceStatus}`) }}
            </el-tag>
        </div>

        <div class="flex justify-end">
            <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('economy.download_pdf')" placement="top">
                <el-button type="primary" size="mini" @click="toPdf(false)">
                    <fa-icon :icon="['fas', 'file-pdf']" fixed-width />
                </el-button>
            </el-tooltip>

            <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('economy.print')" placement="top">
                <el-button type="primary" size="mini" class="btn-print" @click="toPdf(true)">
                    <fa-icon :icon="['fas', 'print']" fixed-width />
                </el-button>
            </el-tooltip>

            <el-dropdown trigger="click" class="ml-2" placement="bottom">
                <el-button type="primary" :loading="$wait.is('*')">
                    {{ $t('economy.change_status') }}<i class="el-icon-arrow-down el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :disabled="!canConfirmInvoice" @click.native="changeStatusTo('confirmed')">
                        {{ $t('economy.mark_as_closed') }}
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="!canMarkAsSent" @click.native="changeStatusTo('sent')">
                        {{ $t('economy.mark_as_sent') }}
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="!canMarkAsUnpaid" @click.native="changeStatusTo('unpaid')">
                        {{ $t('economy.mask_as_unpaid') }}
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="!canMarkAsPaid" @click.native="changeStatusTo('paid')">
                        {{ $t('economy.mask_as_paid') }}
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="!canMarkRutAsSent" @click.native="changeStatusTo('rut_sent')">
                        {{ $t('economy.mark_as_rut_sent') }}
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="!canMarkAsCompleted" @click.native="changeStatusTo('completed')">
                        {{ $t('economy.mark_as_completed') }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown trigger="click" class="ml-2" placement="bottom-end">
                <el-button type="primary" :loading="$wait.is('*')">
                    {{ $t('economy.menu') }}<i class="el-icon-arrow-down el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :disabled="!canSendEmail" @click.native="sendToClient">
                        {{ $t('economy.send_to_client') }}
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="addItem">
                        {{ $t('economy.add_item') }}
                    </el-dropdown-item>
                    <el-dropdown-item divided @click.native="showModalEditInvoice">
                        {{ $t('economy.edit') }}
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="!userCan('delete invoices')" divided @click.native="deleteInvoice">
                        {{ $t('economy.delete') }}
                    </el-dropdown-item>
                    <el-dropdown-item divided @click.native="sendToFortnox">
                        Send to Fortnox
                    </el-dropdown-item>
                    <!-- <el-dropdown-item :disabled="!canConfirmInvoice" @click.native="changeStatusTo('confirmed')">
                        {{ $t('economy.mark_as_closed') }}
                    </el-dropdown-item> -->
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
import includes from 'lodash/includes';

export default {
    components: {
        ModalAddItem: () => import(/* webpackChunkName: "Menu/ModalAddItem" */ './ModalAddItem'),
    },

    props: {
        invoiceUuid: {
            type:    String,
            default: null,
        },
        invoiceStatus: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            modalAddItemVisible: false,
            modalAddItem:        false,
        };
    },

    computed: {
        canConfirmInvoice() {
            return includes(['draft'], this.invoiceStatus);
        },

        canMarkAsSent() {
            return includes(['confirmed'], this.invoiceStatus);
        },

        canMarkAsPaid() {
            return includes(['sent', 'unpaid'], this.invoiceStatus);
        },

        canMarkAsUnpaid() {
            return includes(['sent', 'paid'], this.invoiceStatus);
        },

        canMarkRutAsSent() {
            return includes(['paid'], this.invoiceStatus);
        },

        canMarkAsCompleted() {
            return includes(['rut_sent'], this.invoiceStatus);
        },

        canSendEmail() {
            return !includes(['draft'], this.invoiceStatus);
        },

        // canCreateReminder() {
        //     return _.includes(["sent", "unpaid"], this.invoiceDetails.status);
        // },

        // canCreditInvoice() {
        //     return _.includes(['calendar_events_saved', 'closed', 'sent', 'unpaid', 'paid', 'rut_sent', 'completed', 'reminder_invoice'],
        //                        this.invoiceDetails.status);
        // },

        // canRemoveInvoice() {
        //     return _.includes(['fresh_created', 'calendar_events_saved'], this.invoiceDetails.status);
        // },

        // canEditInvoiceData() {
        //     return _.includes(["fresh_created", "calendar_events_saved", "reminder_invoice"], this.invoiceDetails.status);
        // },

        // canEditMaterialsCost() {
        //     return _.includes(["fresh_created", "calendar_events_saved", "closed", "sent", "paid"], this.invoiceDetails.status);
        // },

        // canAddDiscount() {
        //     return _.includes(["fresh_created", "calendar_events_saved"], this.invoiceDetails.status) && !this.invoiceDetails.has_discount;
        // },

        // canAddService() {
        //     return _.includes(["fresh_created", "calendar_events_saved"], this.invoiceDetails.status);
        // },

        // canEditClientData() {
        //     return _.includes(['calendar_events_saved'], this.invoiceDetails.status);
        // },

        // editExistingClient() {
        //     return _.includes(['fresh_created'], this.invoiceDetails.status);
        // },

        // canPrintOrDownload() {
        //     return _.includes(['fresh_created', 'calendar_events_saved', 'closed', 'sent', 'unpaid', 'paid', 'rut_sent', 'completed',
        //                        'credited', 'reminder_invoice'], this.invoiceDetails.status);
        // },

        // canChangePaymentType() {
        //     return _.includes(['closed', 'sent', 'unpaid', 'reminder_invoice'], this.invoiceDetails.status);
        // },
    },

    methods: {
        async changeStatusTo(status) {
            this.$wait.start('updating');

            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/status_to_${status}`, {
                invoices:  [this.invoiceUuid],
                requestId: 'justRequest',
            });

            this.$emit('refresh');
            this.$wait.end('updating');
            this.$notify.success({ title: this.$t('common.success') });
        },

        async toPdf(toPrint = false) {
            const { data } = await axios.post(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/invoice_pdf_sign_route`, {
                invoice_uuid: [this.invoiceUuid],
                print:        toPrint,
            });

            window.open(data);
        },

        async sendToClient() {
            this.$wait.start('updating');
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/send_email`, {
                invoices: [this.invoiceUuid],
            });

            this.$wait.end('updating');
            this.$emit('refresh');
            this.$notify.success({ title: this.$t("common.success") });
        },

        async sendToFortnox() {
            this.$wait.start('updating');
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/send_to_fortnox`, {
                invoices: [this.invoiceUuid],
            });

            this.$wait.end('updating');
            this.$notify.success({ title: this.$t("common.success") });
        },

        async addItem() {
            this.modalAddItemVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalAddItem = true;
        },

        deleteInvoice() {
            this.$confirm(this.$t("common.are_you_sure"), this.$t("common.warning"), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                beforeClose:       async (action, instance, done) => {
                    if (action === 'confirm') {
                        // @Filip
                        instance.confirmButtonLoading = true;
                        instance.closeOnClickModal = false;
                        instance.showCancelButton = false;
                        instance.closeOnPressEscape = false;

                        try {
                            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices`, {
                                params: {
                                    invoices: [this.invoiceUuid],
                                },
                            });
                            this.$notify.success({ title: 'Success' });
                            this.$emit('deleted');
                        } catch {
                            return;
                        }

                        done();
                        instance.confirmButtonLoading = false;
                        instance.closeOnClickModal = true;
                        instance.showCancelButton = true;
                        instance.closeOnPressEscape = true;
                    } else {
                        done();
                    }
                },
            }).catch(() => {});
        },

        showModalEditInvoice() {
            this.$emit('editInvoice');
        },
    },
};
</script>
